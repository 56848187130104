<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
                   <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
          <div class="mt-3" v-if="windowWidth >= 992" style="padding:0% 2%">

   <div class="display-div mb-2 point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; max-width:300px;"   v-if="windowWidth <= 1270 && windowWidth >= 770" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"  @click="copyRefCode($event)">
                                        <div >

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>
                                         <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>


                    <div class="display-div" style="justify-content:space-between; align-items:center" v-if="windowWidth > 769">

                        <div  style=" flex-basis:30%" :class="{'flex-22': windowWidth < 1614}" >
                     

                                <!-- <i class="fa fa-chevron-down" ></i> -->
                            </div>

                                <div style="flex-basis:40%; display:flex; justify-content:center" v-if="windowWidth > 1270" :class="{'flex-36': windowWidth < 1614}">

                                    <div  style="max-width:300px">
                                    <div class="display-div point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"   @click="copyRefCode($event)">
                                        <div>

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>

                                           <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                               
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20" title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>
                                                </div>
                                            </div>
                                               <div style="flex-basis:30%; display:flex; justify-content:flex-end" :class="{'flex-38': windowWidth < 1614}">
                                            <div>
                                                <div style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center;  padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/bookIcon3.png"  height="20">

              </div>
                   <div>
                 <p style="color: #828282; font-size:12px" class="text-right"> Current booking total </p>


                      <h5 class="text-right">
                           <span> &#8358; </span> {{formatAmount(totalBookedValue)}}
                      </h5>


                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

                                                          </div> -->
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                      <div >
                                                <div class="ml-2" style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/info.png"  height="20">

              </div>
                                                        <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current accrued interest</p>

            <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}
                    </h5>
                      <!-- <p class="text-right">retrieving value...</p> -->
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>
</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
  <div class="book-home-cover">
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12 col-12 col-sm-12  offset-xl-4 offset-lg-3">
                <div  :class="{'mb-bg':windowWidth < 768}">
                <div class="mt-4 text-center">
                  <img src="../../../assets/images/handshake.png" height="200">
                </div>

                <div v-if="bookingHistory.length < 1" class="text-center mt-4 ">
                <h5 style="color:#bdbdbd">Lets get started.......</h5>


                </div>
                    <div class="mt-4" style="border-bottom:0.5px solid #E0E0E0;"   v-if=" bookingHistory.length > 0">
                
                    <div style=" min-height:30px; display:flex; align-items:center min-width:100%">
                      <div style=" min-height:20px; flex:1 ">
                        <p style="color:#828282; font-size:14px">Current booking total</p>
                        <p style="color:#828282; font-size:18px" > <span>&#8358;</span> {{formatAmount(totalBookedValue)}}</p>
                      </div>
                       <div style="justify-content:space-between">
                         <p style="color:#828282; font-size:14px; opacity:0">Current Booking Total</p>
                        <p class="text-right" style="color:#828282; font-size:14px" >At {{bookingRate}}% per annum</p>
                      </div>
                  
                    </div>
                    <div class="mt-1 mb-1" style="border-top: 0.5px solid #E0E0E0;"></div>
                    <div  style=" min-height:40px; display:flex; align-items:center; min-width:100%">
                         <div class="mt-1" style="flex:1">
                        <p style="color:#828282; font-size:14px">Current accrued interest</p>
                        <p style="color:#2FA8A8; font-size:18px">    <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}</p>
                      </div>
                      <div class="mt-1 text-right" style="border-left:1px solid #E0E0E0; flex:1">
<span style="color:#0094FF" class="mr-3 point-cursor" @click="sendToBookHistory">  See list </span>
  <img src="../../../assets/images/rightarrow.png" class="mr-2" height="10">
                      </div>  
                    </div>
                    </div>
                  </div>

              </div>
<div class="col-md-12"></div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-12 col-sm-6  offset-xl-2 offset-lg-2  mt-4">
           
                <div class="booking-card point-cursor" @click.stop="sendToBookAmount">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/bookellips.png"
                      height="80"
                      style="    position: absolute;right: -11px;top: 70px;"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div>
                      <img src="../../../assets/images/bookcol.png" height="40">
                    </div>
                    <div class="text-right">
                      <img src="../../../assets/images/info.png" height="30" @click.stop="sendToExplainer">
                    </div>
                  </div>
                  <div style="color:#828282; min-height:60px;">
                    <p>. Earn interest from your wallet or card deposits .</p>
                    <p>. Withdraw anytime</p>
                  </div>

                  <h5 style="color:#4F4F4F">Book from wallet</h5>
                </div>

                <!-- Next page -->
              

           
           
              </div>
              <div class="col-xl-4 col-lg-4  col-md-6  col-sm-6  col-12  mt-4">
                <div class="booking-card" style="opacity:0.5">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/bookellips2.png"
                      height="80"
                      style="    position: absolute;right: -11px;top: 70px; z-index:0"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div>
                      <img src="../../../assets/images/auto-book.png" height="40">
                    </div>
                    <div class="text-right">
                      <img src="../../../assets/images/info.png" class="point-cursor" height="30"  title="This feature is coming soon"  v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                    </div>
                  </div>
                  <div style="color:#828282; min-height:60px; position:relativeposition:relative">
                    <p>Autobook from portfolio investments and payment cards</p>
                  </div>

                  <h5 style="color:#4F4F4F;position:relative">Autobook funds - Auto-pilot</h5>
                </div>
              </div>
          </div>
        </div>

    
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
    sendToBookAmount() {
      this.$router.push("/book/amount");
    },
    sendToBookHistory() {
      this.$router.push("/book/history");
    },
    sendToExplainer() {
      this.$router.push("/book/explainer");
    },
   
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
    bookingRate: state => state.market.bookingSettings.default_rate,
      bookingMinimumAmount: state => state.market.bookingSettings.minimum_amount,
      bookingDuration: state => state.market.bookingSettings.minimum_duration_days,
        bookingHistory: state => state.market.bookingHistory,
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.booking
    };
    this.$store.dispatch("market/getBookFundsSummary");
    this.$store.commit("market/savePageHeading", heading);
    this.$store.dispatch("market/getBookingHistory");
  this.$store.dispatch("market/getBookingSettings");
  this.$store.commit(
      "auth/saveBookRouteVisited",
      this.$route.path
    );
  },


};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {

  min-height: 70vh;
  display: flex;
  align-items: center;
    padding-bottom: 5%;
}

.mb-bg{

    background: #FAFAFA;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;
}
</style>
