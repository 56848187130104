<template>
<div>
    <bookingHome />
</div>
</template>

<script>
import bookingHome from '../../components/dashboard/booking/BookingHome'
export default {
    components: {
        bookingHome
    }
}
</script>

<style>
